<template>
  <b-modal
    id="delete-configuration-modal"
    title="Delete Build Configuration"
    size="lg"
    centered
    @ok="deleteSelectedConfiguration"
  >
    <span class="font-medium-2">Confirm deletion of the below configuration:</span>
    <hr class="mb-2">
    {{ selectedConfiguration.name }}
    <div>{{ selectedConfiguration.description }}</div>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" @click="ok()">
        Delete
      </b-button>
      <b-button variant="outline-dark" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedConfiguration: state => state.configurations.selectedConfiguration,
    }),
  },
  methods: {
    deleteSelectedConfiguration(evt) {
      evt.preventDefault()
      this.$store.dispatch('configurations/deleteConfiguration', this.selectedConfiguration.id).then(() => {
        this.$bvModal.hide('delete-configuration-modal')
      })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
