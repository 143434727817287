<template>
  <div>
    <div v-if="loading">
      <div class="w-100">
        <h1 class="mt-4 font-medium-3 text-center">
          Loading Configuration Information...
        </h1>
        <h5 class="text-center text-warning">
          <b-spinner />
        </h5>
      </div>
    </div>

    <b-container v-else>
      <b-row>
        <b-col>
          <b-button-group class="float-right mb-2">
            <!-- TODO: Change this to a route to a page -->
            <b-button variant="outline-success" @click="routeToConfig">
              Create Build Configuration
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="!loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="btn-icon"
              @click="fetchConfigurations"
            >
              <feather-icon icon="RefreshCcwIcon" />
              Refresh
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-group deck>
            <ConfigurationCard
              v-for="(cfg, index) in configurations"
              :key="`rel-${index}`"
              :config-object="cfg"
            />
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>

    <DeleteConfigurationModal />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiStatus } from '@/enums'
import ConfigurationCard from '@/components/Configurations/Cards/ConfigurationCard.vue'
// import AddReleaseModal from '@/components/Releases/Modals/AddReleaseModal.vue'
// import EditReleaseModal from '@/components/Releases/Modals/EditReleaseModal.vue'
import DeleteConfigurationModal from '@/components/Configurations/Modals/DeleteConfigurationModal.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Configurations',
  components: {
    DeleteConfigurationModal,
    ConfigurationCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected_configuration: {
        name: '',
        description: '',
      },
      sort: 'end',
      apiStatus,
      loading: true,
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapState({
      configurations: state => state.configurations.configurations,
    }),
  },
  mounted() {
    // TODO Refactor this. Too many things loading at the same time.
    if (!this.$store.state.model.id) {
      setTimeout(this.getInitialData, 1000)
    } else {
      this.getInitialData()
    }
  },
  methods: {
    getInitialData() {
      this.$store.dispatch('configurations/getConfigurations')
      this.$store.dispatch('domainModel/getComponents')
      this.$store.dispatch('interfaces/fetchInterfaces')
      this.loading = false
      this.getData()
    },
    fetchConfigurations() {
      this.loading = true
      this.$store.dispatch('configurations/getConfigurations', this.sort)
        .then(() => {
          setTimeout(() => { this.loading = false }, 300)
        })
    },
    switchSort() {
      if (this.sort === 'end') {
        this.sort = 'start'
      } else {
        this.sort = 'end'
      }
      this.fetchConfigurations()
    },
    routeToConfig() {
      console.log('Clearing configs: ')
      this.$store.dispatch('configurations/clearConfigurations')
      this.$router.push(
        {
          name: 'configuration_details',
          params: { id: false },
        },
      )
    },
    getData() {
      // this.isDataLoaded = false
      // const params = { model: this.$store.state.model.id }
      // this.$http.get('/api/v2/interfaces', { params })
      //   .then(({ data }) => {
      //     this.interfaces = data
      //   })
      //   .finally(() => {
      //     this.$refs.edit_release_model.removeDuplicatesForAssociators()
      //     this.isDataLoaded = true
      //   })
    },
  },
}
</script>
