<template>
  <b-card
    no-body style="min-width: 30%; max-width: 30%" class="my-1"
  >
    <b-card-header class="">
      <b-card-title>{{ config.name }}</b-card-title>
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="routeToConfig(config.id)">
          <feather-icon icon="EditIcon" class="cursor-pointer" />
          <span class="ml-50">Update</span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteConfig(config.id)">
          <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" />
          <span class="text-danger ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="mb-2">
      <b-card-text>
        <div>{{ config.description }}</div>
        <b-row class="mt-50">
          <b-col>
            <b>Version</b>:
            <span>{{ config.version }}</span>
          </b-col>
          <b-col>
            <b>Date</b>:
            <span v-if="config.created && config.created != ''">{{ config.created | moment }}</span>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
    <b-card-footer>
      <div @click="routeToConfig(config.id)">
        <b-button-group vertical size="lg" style="width:100%">
          <b-button variant="light" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ config.components.length }}
            </b-badge> Components Allocated
          </b-button>
          <b-button variant="light" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ config.instances.length }}
            </b-badge> Instances Allocated
          </b-button>
          <b-button variant="light" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ config.interfaces.length }}
            </b-badge> Interfaces Allocated
          </b-button>
          <b-button variant="light" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ config.tests.length }}
            </b-badge> Test Cases Allocated
          </b-button>
        </b-button-group>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  name: 'ConfigurationCard',
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('LL')
    },
  },
  props: {
    configObject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    config() {
      return this.configObject
    },
  },
  methods: {
    updateConfig(configId) {
      console.log('Selecting config: ', configId)
      this.$store.dispatch('configurations/selectConfiguration', configId)
      // TODO: Replace with route....this.$bvModal.show('modal__ReleaseEdit')
    },
    deleteConfig(configId) {
      console.log('Selecting config: ', configId)
      this.$store.dispatch('configuration/selectConfiguration', configId)
      this.$bvModal.show('delete-configuration-modal')
    },
    routeToConfig(node) {
      console.log('Selecting config: ', node)
      this.$store.dispatch('configurations/selectConfiguration', node)
      this.$router.push(
        {
          name: 'configuration_details',
          params: { id: node },
        },
      )
    },
  },

}
</script>
